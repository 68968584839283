<template>
  <b-modal v-model="modalVisible" :id="this.modalId" size="lg" centered :title="this.groupMemberName" header-class="rm-panel-heading">
    <div class="row">
      <div class="col">
        {{ $t('mentorship.removeFromGroup.pt1') }}
        <strong>{{this.groupMemberName}}</strong>
        {{ $t('mentorship.removeFromGroup.pt2') }}
        <strong>{{this.studentGroupName}}</strong>?
      </div>
    </div>
    <div class="row">
      <div class="col">
        {{ $t('mentorship.removeFromGroup.explanation') }}
      </div>
    </div>
    <template #modal-footer>
      <b-button variant="primary" :disabled="saving" @click="handleSubmit">{{ $t("mentorship.remove") }}</b-button>
      <b-button variant="secondary" :disabled="saving" @click="handleClose">{{ $t("mentorship.cancel") }}</b-button>
    </template>
  </b-modal>
</template>

<script>
import {mentorshipService} from "@/_services/mentorship.service";

export default {
  name: "RemoveStudentGroupMemberModal",
  data() {
    return {
      modalVisible: false,
      errorRemoving: false,
      successRemoving: false,
      saving: false,
      error: "",
    }
  },
  props: {
    modalId: {
      type: String,
      required: true,
    },
    studentGroupId: {
      type: String,
      required: true,
    },
    studentGroupName: {
      type: String,
      required: true,
    },
    groupMemberId: {
      type: String,
      required: true,
    },
    groupMemberName: {
      type: String,
      required: true,
    }
  },
  methods: {
    initData(savingValue) {
      this.errorRemoving = false;
      this.successRemoving = false;
      this.error = ""
      this.saving = savingValue;
    },
    handleSubmit() {
      this.errorRemoving = false;
      this.saving = true;

      mentorshipService.removeStudentGroupMember(this.studentGroupId, this.groupMemberId)
        .then(() => {
          this.successRemoving = true;
          this.$emit("reloadStudentGroupMembers");
          return new Promise(resolve => {
            setTimeout(() => {
              resolve();
            }, 1000);
          })
          .then(() => {
            // do not set saving to false (we don't want to enable buttons since popup will close in any way)
            this.modalVisible = false;
            this.initData(true);
          })
          .catch((error) => {
            this.saving = false;
            this.errorRemoving = true;
            this.error = error;
          });
        }
      )
    },
    handleClose() {
      this.modalVisible = false;
      this.initData(false);
    }
  },
  created() {
    this.initData(false);
  }
}
</script>