<template>
  <b-tab>
    <template slot="title">
      <span class="d-sm-none">{{ $t("mentorship.studentGroupMembers") }} ({{ studentGroupMembers.length }})</span>
      <span class="d-sm-block d-none">{{ $t("mentorship.studentGroupMembers") }} ({{ studentGroupMembers.length }})</span>
    </template>
    <div class="row justify-content-end m-b-10">
      <b-button v-b-modal="'add-student-group-member-modal'" variant="primary" class="m-r-10"><i class="fa fa-plus m-r-10" aria-hidden="true"></i>{{ this.$t("mentorship.addStudentGroupMember") }}</b-button>
    </div>
    <hr/>
    <div class="row m-t-20">
      <div v-for="(sgMember, idx) in studentGroupMembers" :key="idx" class="col-xs-12 col-sm-12 col-md-6 col-xl-4 col-xxl-4 col-xxxl-4">
        <RMPanel :title="sgMember.name">
          <template v-slot:panelBody>
            <div class="d-flex justify-content-between">
              <span>{{ $t("mentorship.studentInfo.email") }}:</span>
              <span>
                <b>{{ sgMember.email }}</b>
                <RMEmail :e-href="getMailtoHref(sgMember)"/>
              </span>
            </div>
            <hr/>
            <div class="d-flex justify-content-end">
              <RemoveStudentGroupMemberModal
                :modal-id="removeModalId(sgMember.userId)"
                :group-member-name="sgMember.name"
                :group-member-id="sgMember.userId"
                :student-group-name="studentGroupName"
                :student-group-id="studentGroupId"
                @reloadStudentGroupMembers="loadStudentGroupMembers"
              />
              <b-link v-b-modal="removeModalId(sgMember.userId)" class="text-danger">{{ $t("assignments.removeGroupMemberLink") }}</b-link>
            </div>
          </template>
        </RMPanel>
      </div>
    </div>
    <NewStudentGroupMemberModal
      @reloadStudentGroupMembers="loadStudentGroupMembers"
      :student-group-id="studentGroupId"
      :student-group-members="studentGroupMembers"
    />
  </b-tab>
</template>

<script>
import {mentorshipService} from "@/_services/mentorship.service";
import {handleError} from "@/_helpers/api";
import router from "@/config/PageRoutes";
import NewStudentGroupMemberModal from "@/pages/mentorship/NewStudentGroupMemberModal.vue";
import RMPanel from "@/components/common/RMPanel.vue";
import RMEmail from "@/components/common/small/RMEmail.vue";
import RemoveStudentGroupMemberModal from "@/pages/mentorship/RemoveStudentGroupMemberModal.vue";

export default {
  name: "StudentGroupMembers",
  components: {RemoveStudentGroupMemberModal, RMEmail, RMPanel, NewStudentGroupMemberModal},
  data() {
    return {
      loadingStudentGroupMembers: false,
      studentGroupMembers: []
    }
  },
  props: {
    studentGroupId: {
      type: String,
      required: true
    },
    studentGroupName: {
      type: String,
      required: true
    },
  },
  methods: {
    removeModalId(memberId) {
      return `remove_sgm_${this.studentGroupId}_${memberId}`;
    },
    loadStudentGroupMembers() {
      this.loadingStudentGroupMembers = true;

      mentorshipService.getStudentGroupMembers(this.studentGroupId).then((data) => {
        this.studentGroupMembers = data.data.members;
        this.loadingStudentGroupMembers = false;
      }).catch((error) => handleError(error, router, this._vm)).finally(() => {
        this.loadingStudentGroupMembers = false;
      })
    },
    getMailtoHref(studentGroupMember) {
      return `mailto:${studentGroupMember.email}`
    },
  },
  created() {
    this.loadStudentGroupMembers();
  }
}
</script>