<template>
  <b-tab>
    <template v-slot:title>
      <span class="d-sm-none">{{ $t("assignments.assignments") }}
        <span v-if="!loadingAssignments">
          <span v-if="assignments.length > 0">
            ({{ assignments.length }})
          </span>
        </span>
      </span>
      <span class="d-sm-block d-none">{{ $t("assignments.assignments") }}
        <span v-if="!loadingAssignments">
          <span v-if="assignments.length > 0">
            ({{ assignments.length }})
          </span>
        </span>
      </span>
    </template>
    <div class="row">
      <div class="col">
        <div class="d-flex justify-content-end">
          <b-button :disabled="loadingAssignments" class="m-r-10" variant="primary" v-on:click="navigateToNewAssignment()">
            <i class="fa fa-plus m-r-10" aria-hidden="true"></i>
            {{ this.$t("assignments.newAssignment") }}
          </b-button>
          <b-button variant="primary" @click="navigateToArchive()">
            <i class="fa fa-box m-r-10" aria-hidden="true"></i>
            {{ this.$t("assignments.myAssignmentsArchive") }}
          </b-button>
        </div>
      </div>
    </div>
    <hr/>
    <div v-if="loadingAssignments" class="row m-t-20">
      <div class="col-12">
        <b-skeleton class="height-200"></b-skeleton>
      </div>
    </div>
    <div v-else-if="this.assignments.length === 0" class="row m-t-20">
      <div class="col-12">
        <b-alert show variant="light" class="text-center" style="font-size: medium">
          <span v-if="entity === 'studentGroups'">{{ $t("assignments.noGroupAssignments") }}</span>
          <span v-if="entity === 'myStudents'">{{ $t("assignments.noStudentAssignments") }}</span>
        </b-alert>
      </div>
    </div>
    <div v-else class="row m-t-20">
      <div v-for="(assignment, idx) in this.assignments" :key="idx" class="col-xs-12 col-sm-12 col-md-6 col-xl-4 col-xxl-4 col-xxxl-4">
        <RMAssignmentPanel :entity="entity" :entity-id="entityId" :assignment="assignment" @reloadAssignments="loadAssignments()"/>
      </div>
    </div>
  </b-tab>
</template>

<script>
import {assignmentService} from "@/_services/assignment.service";
import {
  getEntityAssignmentsArchiveRoute,
  getNewAssignmentRoute,
} from "@/_helpers/routes_helper";
import RMAssignmentPanel from "@/components/assignments/RMAssignmentPanel.vue";

export default {
  name: "AssignmentsList",
  props: {
    entity: {
      type: String,
      required: true
    },
    entityId: {
      type: String,
      required: true
    },
  },
  data() {
    return {
      loadingAssignments: false,
      assignments: []
    }
  },
  components: {RMAssignmentPanel},
  methods: {
    navigateToArchive() {
      this.$router.push(getEntityAssignmentsArchiveRoute(this.entity, this.entityId))
    },
    navigateToNewAssignment() {
      this.$router.push(getNewAssignmentRoute(this.entity, this.entityId));
    },
    loadAssignments() {
      this.loadingAssignments = true;

      assignmentService.listAssignments(this.entity, this.entityId).then((data) => {
        this.assignments = data["assignments"];
        this.loadingAssignments = false;
      })
    },
  },
  created() {
    this.loadAssignments();
  }
}
</script>