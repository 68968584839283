<template>
  <RMPanel
    :title="getAssTitleHeader(assignment)"
    :title-is-html="true"
  >
    <template v-slot:panelBody>
      <div class="d-flex justify-content-between">
        <span>{{ $t("assignments.numberOfTasks") }}:</span>
        <span><b>{{ assignment.numberOfTasks }}</b></span>
      </div>
      <hr/>
      <div class="d-flex justify-content-between">
        <span>{{ $t("assignments.duration") }}:</span>
        <div>
          <div class="d-flex justify-content-end">
            {{ $t("assignments.durations.from") }}
            &nbsp;
            <b>{{new Date(assignment.startDate).toLocaleString(getDateTimeLocale()) }}</b>
          </div>
          <div class="d-flex justify-content-end">
            {{ $t("assignments.durations.to") }}
            &nbsp;
            <b>{{ new Date(assignment.endDate).toLocaleString(getDateTimeLocale()) }}</b>
          </div>
        </div>
      </div>
      <hr/>
      <div class="d-flex justify-content-between">
        <span>{{ $t("assignments.percentageOfStudentsDone") }}:</span>
        <span><b>{{ assignment.numStudentsFinished }} / {{ assignment.numAllStudents }}</b></span>
      </div>
      <div class="m-t-15">
        <RMProgressBar :num-all="assignment.numAllStudents" :num-solved="assignment.numStudentsFinished"/>
      </div>
      <hr/>
      <div class="d-flex justify-content-end">
        <b-link class="align-self-center" @click="navigateToAssignmentPage(assignment)">{{ $t("assignments.detailsLink") }}</b-link>
        <b-link v-if="isTimeOverdue(assignment) && assignment.userStatus !== 'ARCHIVED'" class="m-l-20 text-danger" variant="danger" size="sm" @click="archiveAssignment()">
          {{ $t("assignments.buttonTexts.archive") }}
        </b-link>
      </div>
    </template>
  </RMPanel>
</template>

<script>
import RMProgressBar from "@/components/common/RMProgressBar.vue";
import RMPanel from "@/components/common/RMPanel.vue";
import {getDateTimeLocale} from "@/_helpers/locale_helper";
import {getAssignmentTitleHeader, isTimeOverdue} from "@/_helpers/assignments_helper";
import {getAssignmentRoute} from "@/_helpers/routes_helper";
import {assignmentService} from "@/_services/assignment.service";
import {showSweetAlert} from "@/_helpers/api";

export default {
  name: "RMAssignmentPanel",
  components: {RMPanel, RMProgressBar},
  props: {
    entity: {
      type: String,
      required: true
    },
    entityId: {
      type: String,
      required: true
    },
    assignment: {
      type: Object,
      required: true
    },
  },
  methods: {
    isTimeOverdue,
    getDateTimeLocale,
    getAssTitleHeader(assessment) {
      return getAssignmentTitleHeader(assessment, this);
    },
    navigateToAssignmentPage(assignment) {
      this.$router.push(getAssignmentRoute(this.entity, this.entityId, assignment.id));
    },
    archiveAssignment() {
      assignmentService.archiveAssignment(this.assignment.id).then((data) => {
        showSweetAlert(data.message, this);
        this.$emit("reloadAssignments");
      })
    }
  }
}
</script>