<template>
  <div>
    <RMPageHeader v-if="!error">
      <template v-slot:header v-if="studentGroup">
        {{ studentGroup.name }}
        <small>
          <b-link v-on:click="navigateToStudentGroupsPage()">({{ $t("mentorship.studentGroup") }})</b-link>
        </small>
      </template>
    </RMPageHeader>
    <div class="row" v-if="error">
      {{ this.error }}
    </div>
    <b-tabs v-else nav-class="mb-3" pills>
      <AssignmentsList
        v-if="studentGroup"
        entity="studentGroups"
        :entity-id="studentGroup.id"
      />
      <StudentGroupMembers
        v-if="studentGroup"
        :student-group-id="studentGroup.id"
        :student-group-name="studentGroup.name"
      />
    </b-tabs>
  </div>
</template>

<script>
import RMPageHeader from "@/components/common/RMPageHeader.vue";
import AssignmentsList from "@/pages/mentorship/assignments/AssignmentsList.vue";
import StudentGroupMembers from "@/pages/mentorship/StudentGroupMembers.vue";
import {mentorshipService} from "@/_services/mentorship.service";
import {getStudentGroupsRoute} from "@/_helpers/routes_helper";

export default {
  name: "StudentGroup",
  components: {StudentGroupMembers, AssignmentsList, RMPageHeader},
  data() {
    return {
      loadingStudentGroup: false,
      loadingError: false,
      error: "",
      studentGroup: null,
    }
  },
  methods: {
    navigateToStudentGroupsPage() {
      this.$router.push(getStudentGroupsRoute());
    },
    loadStudentGroup() {
      this.loadingStudentGroup = true;

      mentorshipService.getStudentGroup(this.$route.params.studentGroupId).then((data) => {
        this.studentGroup = data.data;
        this.loadingStudentGroup = false;
      }).catch((error) => {
        this.loadingError = true;
        this.error = error;
      })
    }
  },
  created() {
    this.loadStudentGroup();
  }
}
</script>